<!-- © 2021 Tfarraj -->
<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="ma-auto">
        <v-card outlined :loading="loading">
          <div class="px-8 pt-6 pb-12">
            <v-card-title class="text-center"
              ><img class="responsive-img" src="/logo.svg" :alt="siteName"
            /></v-card-title>
            <v-card-subtitle v-if="forgotStatus" class="mb-5">
              <v-card-subtitle class="mb-5">{{ this.message }}</v-card-subtitle>
              <v-btn
                type="submit"
                class="primary"
                :loading="loading"
                depressed
                tabindex="3"
                to="/"
                >{{ $t('resetPassword.goHome') }}
              </v-btn>
            </v-card-subtitle>
            <v-card-subtitle v-if="resetStatus" class="mb-5">
              <v-card-subtitle class="mb-5">{{ this.message }}</v-card-subtitle>
              <v-btn
                type="submit"
                class="primary"
                :loading="loading"
                depressed
                tabindex="3"
                to="/"
                >{{ $t('resetPassword.goHome') }}
              </v-btn>
            </v-card-subtitle>
            <v-card-subtitle v-if="token && !resetStatus" class="mb-5"
              >{{ $t('resetPassword.enterNewPassword') }}
            </v-card-subtitle>
            <v-card-subtitle v-if="!token && !forgotStatus" class="mb-5 mt-5"
              >{{ $t('resetPassword.enterEmail') }}
            </v-card-subtitle>
            <v-card-text v-if="token && !resetStatus">
              <ValidationObserver
                ref="resetPasswordForm"
                v-slot="{ handleSubmit, reset }"
              >
                <form
                  @submit.prevent="handleSubmit(resetPassword)"
                  @reset.prevent="reset"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="newPassword"
                    rules="required|password:@newPasswordConfirmation|min:8"
                  >
                    <v-text-field
                      v-model="newPassword"
                      type="password"
                      v-bind:error-messages="
                        errors != '' ? $t('resetPassword.' + errors) : ''
                      "
                      v-bind:label="$t('resetPassword.password')"
                      outlined
                      tabindex="2"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="newPasswordConfirmation"
                    rules="required"
                  >
                    <v-text-field
                      type="password"
                      v-model="newPasswordConfirmation"
                      v-bind:error-messages="
                        errors != '' ? $t('resetPassword.' + errors) : ''
                      "
                      v-bind:label="$t('resetPassword.confirmPassword')"
                      outlined
                      tabindex="2"
                    ></v-text-field>
                  </ValidationProvider>
                  <div class="mt-6 d-flex justify-end">
                    <v-btn
                      type="submit"
                      class="primary"
                      :loading="loading"
                      depressed
                      tabindex="3"
                      >{{ $t('resetPassword.save') }}</v-btn
                    >
                  </div>
                </form>
              </ValidationObserver>
            </v-card-text>
            <v-card-text v-if="!token && !forgotStatus">
              <ValidationObserver
                ref="forgotPasswordForm"
                v-slot="{ handleSubmit, reset }"
              >
                <form
                  @submit.prevent="handleSubmit(forgotPassword)"
                  @reset.prevent="reset"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="email"
                      :error-messages="errors"
                      v-bind:label="$t('resetPassword.email')"
                      outlined
                      autofocus
                      tabindex="1"
                    ></v-text-field>
                  </ValidationProvider>

                  <div class="mt-6 d-flex justify-end">
                    <v-btn
                      type="submit"
                      class="primary"
                      :loading="loading"
                      depressed
                      tabindex="3"
                      >{{ $t('resetPassword.sendResetLink') }}</v-btn
                    >
                  </div>
                </form>
              </ValidationObserver>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Config from '@/config';
import UserService from '@/services/UserService';

export default {
  name: 'ResetPassword',
  data: () => ({
    siteName: Config.siteName,
    token: '',
    resetStatus: false,
    forgotStatus: false,
    email: '',
    newPassword: '',
    newPasswordConfirmation: '',
    error: '',
    message: '',
    loading: false,
  }),
  methods: {
    async forgotPassword() {
      this.loading = true;
      this.message = this.$i18n.t('resetPassword.forgotPasswordLoading');

      try {
        await UserService.forgotPassword({ email: this.email });
        this.message = this.$i18n.t('resetPassword.resetPasswordLinkSent');
        this.forgotStatus = true;
      } catch (e) {
        console.error(e);
        this.forgotStatus = true;
        this.message = this.$i18n.t('resetPassword.forgotPasswordFailed');
      } finally {
        this.loading = false;
      }
    },
    async resetPassword() {
      this.loading = true;
      this.message = this.$i18n.t('resetPassword.resetPasswordLoading');

      try {
        await UserService.resetPassword({
          token: this.token,
          password: this.newPassword,
        });
        this.message = this.$i18n.t('resetPassword.resetPasswordSuccess');
        this.resetStatus = true;
      } catch (e) {
        console.error(e);
        this.resetStatus = true;
        this.message = this.$i18n.t('resetPassword.resetPasswordFailed');
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    this.token = this.$route.query.token;
  },
};
</script>

<style></style>
